// import React, { useCallback, useState } from "react";
import styles from "../../../../Home.module.css";
import { useFormApi } from "@data-driven-forms/react-form-renderer";
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import { useTranslation } from "react-i18next";
import Layout from "../../../layout/Layout";
import "./style.css";
import { FORM_FIELD_NAMES } from "../../../../constants/form-field-names";
// import CustomAccordian from "../../../custom-accordian/CustomAccordian";
import AccordionModelTypeOne from "./AccordionModelTypeOne";
import AccordionModelTypeOther from "./AccordionModelTypeOther";
import Existing from "./Existing";
import ModelTypeOne from "./ModelTypeOne";
import { applePayController } from "../../../apple-pay-button/components/apple-pay";
import PCI_LOGO from "../../../assets/pci.svg";


const FormTemplate = ({ formFields }: any) => {
  const { handleSubmit, getState, onCancel } = useFormApi();
  const { values } = getState();
  const { t } = useTranslation();

  const amountField = formFields.filter((item: any) => {
    return ["amount"].includes(item.props.name);
  });
  const cardFields = formFields.filter((item: any) => {
    return [
      "listener",
      "Enter Amount",
      "name_on_card",
      "firstname",
      "lastname",
      "cardnumber",
      "cvv",
      "monthyear",
      "hasVISA",
      "hasMastercard",
      "hasJCB",
      "hasAmex",
      "hasDinersClub",
    ].includes(item.props.name);
  });
  const billingInfoLabel = formFields.filter((item: any) => {
    return [
      FORM_FIELD_NAMES.BILLING_INFORMATION,
    ].includes(item.props.name);
  });
  const emailAndPhone = formFields.filter((item: any) => {
    return [
      FORM_FIELD_NAMES.EMAIL,
      FORM_FIELD_NAMES.PHONE,
    ].includes(item.props.name);
  });
  const countryAndPostalCode = formFields.filter((item: any) => {
    return [
      FORM_FIELD_NAMES.COUNTRY,
      FORM_FIELD_NAMES.POSTAL_CODE,
    ].includes(item.props.name);
  });
  const countryField = formFields.filter((item: any) => {
    return [
      FORM_FIELD_NAMES.COUNTRY
    ].includes(item.props.name);
  });
  const postalCodeField = formFields.filter((item: any) => {
    return [
      FORM_FIELD_NAMES.POSTAL_CODE,
    ].includes(item.props.name);
  });
  const cityAndAddress = formFields.filter((item: any) => {
    return [
      FORM_FIELD_NAMES.CITY,
      FORM_FIELD_NAMES.ADDRESSLINEONE,

    ].includes(
      item.props.name
    );
  });
  const memoField = formFields.filter((item: any) => {
    return [
      FORM_FIELD_NAMES.MEMO
    ].includes(
      item.props.name
    );
  });

  /*  HPPconfig
     model 0 : Add the Accordion , also need to pass hideBillingAddress:true to hide 
     model 1 : Specific design for IBEC merchant 
     model null : Without Accordion 
  */

  const hideBillingAddress = values?.config?.hideBillingAddress || false;
  const modelType = values?.config?.modelType || 0;

  const props = { hideBillingAddress, billingInfoLabel, emailAndPhone, cityAndAddress, countryField, postalCodeField, countryAndPostalCode };

  const BillingAddressComponent = () => {
    switch (modelType) {
      case 0:
        if (hideBillingAddress) {
          return <AccordionModelTypeOther {...props} />
        } else {
          return <Existing {...props} />
        }
        break;
      case 1:
        if (hideBillingAddress) {
          return <AccordionModelTypeOne {...props} />
        } else {
          return <>
            {billingInfoLabel}
            <ModelTypeOne {...props} />
          </>
        }
        break;
      default:
        return <Existing {...props} />;

    }
  };

  const isIbec = modelType === 1 ? true : false;

  const applePaySessionAvailable = applePayController.isAppleSessionAvailable();

  const GpayApplePayAlignment = isIbec && applePaySessionAvailable && values?.hasApplePay && values?.hasGooglePay
    ? "vertical-alignment" : "";

  const gpayApplePayTitle = isIbec ? `gpayApplePayTitle ${applePaySessionAvailable ? "applePaySessionAvailable" : ""}` : ""

  //const noApplePayGooglePayButtonsPresent = isIbec || (values.hasGooglePay === false && values.hasApplePay === false)
  const noApplePayGooglePayButtonsPresent = (values.hasGooglePay === false && values.hasApplePay === false && values.hasPayPay === false ) || (values.hasGooglePay === false && values.hasPayPay === false && values.hasApplePay === true && !applePaySessionAvailable)
  //const noApplePayGooglePayButtonsPresent = isIbec || ((values.hasGooglePay === false && values.hasApplePay === false) || (values.hasGooglePay === false && values.hasApplePay === true && !applePaySessionAvailable))
  return (
    <>
      <form
        className="payment-card-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit()
          localStorage.setItem("hppemail", values.email)
        }}
        autoComplete="Off"
      >
        <div>
          <Layout
            className={
              noApplePayGooglePayButtonsPresent
                ? `layout layout-cards remove-padding ${gpayApplePayTitle} ${GpayApplePayAlignment}`
                : `layout layout-cards ${gpayApplePayTitle} ${GpayApplePayAlignment}`
            }
          >
            {values.subscription ? <div></div> : amountField}
            {cardFields}

            {BillingAddressComponent()}
            {!hideBillingAddress && memoField}

            <FormSpy subscription={{ pristine: true }}>
              {() => (
                <div className="action-buttons" style={{ marginTop: 22 }}>
                  <button
                    className={styles.cancelbtn}
                    onClick={onCancel}
                    type="reset"
                    disabled={false}
                  >
                    {t("merchant.button.cancel")}
                  </button>

                  <button className="submitbtn" type="submit" disabled={false}>
                    {t(
                      values?.subscription
                        ? "merchant.button.SubscribeNow"
                        : "merchant.button.PayNow"
                    )}
                  </button>
                </div>
              )}
            </FormSpy>
            {
              !isIbec &&
              <div className="pci-logo-div">
                <img className="pci-logo" src={PCI_LOGO} alt="pci logo" />
              </div>
            }
          </Layout>
        </div>
      </form>

    </>
  );
};
export default FormTemplate;
